import React, { useState, useEffect } from 'react';
import './contactform.css';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactForm = () => {
  const [showOffseasonHours, setShowOffseasonHours] = useState(false);
  const [showGroupSize, setShowGroupSize] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <section className="contact-section" id='contact'>
      <div className="container">
        <div className="row">
          <div className="col-md-6" data-aos="fade-up">
            <h2 className="contact-title">Kontaktieren Sie uns</h2>

            {/* Kontaktformular */}
            <form className="contact-form" method="POST" action="contact-form.php">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">E-Mail</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Betreff</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  required
                />
              </div>

              {/* Button zum Anzeigen/Ausblenden des Gruppengröße-Dropdowns */}
              <button
                type="button"
                className="toggle-group-size-btn"
                style={{ marginBottom: '1rem' }}
                onClick={() => setShowGroupSize(!showGroupSize)}
              >
                {showGroupSize ? 'Gruppendetails verbergen' : 'Gruppendetails hinzufügen'}
              </button>

              {showGroupSize && (
                <>
                <div className="form-group">
                  <label htmlFor="groupSize">Gruppengröße (optional)</label>
                  <select id="groupSize" name="groupSize">
                    <option value="">Bitte wählen</option>
                    <option value="1-5">1-5 Personen</option>
                    <option value="6-10">6-10 Personen</option>
                    <option value="11-20">11-20 Personen</option>
                    <option value="21+">Mehr als 20 Personen</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="groupSize">Sitzplatz</label>
                  <select id="seatlocation" name="seatlocation">
                    <option value="Dachterasse">Dachterrasse</option>
                    <option value="Erdgeschoss">Biergarten (Ebenerdig)</option>
                  
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="groupSize"> Wünschen Sie Bedienung</label>

                  <select id="server" name="server">
                    <option value ="none">(Bitte wählen)</option>
                    <option value="Ja">Ja</option>
                    <option value="Nein">Nein</option>
                  
                  </select>
                </div>

           
</>
              )}

              <div className="form-group">
                <label htmlFor="message">Nachricht</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    name="privacyPolicy"
                    required
                  />
                  Ich habe die <a href="/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> gelesen und bin damit einverstanden.
                </label>
              </div>

              <button type="submit" className="cta-button-submit">Nachricht senden</button>
            </form>
          </div>

          <div className="col-md-6" data-aos="fade-up">
            <h2 className="contact-info-title text-left">Unsere Kontaktinformationen</h2>
            <div className="contact-info-container">
              <div className="contact-info">
                <p><FaPhoneAlt /><a href="tel:0157 718 42593" style={{ textDecoration: 'none', color: 'black' }}>0157 718 42593</a></p>
                <p><FaEnvelope /><a href="mailto:gastro.hafen@gmail.com" style={{ textDecoration: 'none', color: 'black' }}>gastro.hafen@gmail.com</a></p>
                <p><FaMapMarkerAlt /> Badhehalbinsel 1, 91720 Absberg</p>
                <h2 className="contact-info-title">Unsere Öffnungszeiten (Hauptsaison)</h2>
                <table className="opening-hours-table">
                  <tbody>
                    <tr>
                      <td>Montag:</td>
                      <td>09:00 - 21:00 Uhr</td>
                    </tr>
                    <tr>
                      <td>Dienstag:</td>
                      <td>09:00 - 21:00 Uhr</td>
                    </tr>
                    <tr>
                      <td>Mittwoch:</td>
                      <td>09:00 - 21:00 Uhr</td>
                    </tr>
                    <tr>
                      <td>Donnerstag:</td>
                      <td>09:00 - 21:00 Uhr</td>
                    </tr>
                    <tr>
                      <td>Freitag:</td>
                      <td>09:00 - 21:00 Uhr</td>
                    </tr>
                    <tr>
                      <td>Samstag:</td>
                      <td>09:00 - 21:00 Uhr</td>
                    </tr>
                    <tr>
                      <td>Sonntag:</td>
                      <td>09:00 - 21:00 Uhr</td>
                    </tr>
                  </tbody>
                </table>

                <button
                  className="toggle-button"
                  onClick={() => setShowOffseasonHours(!showOffseasonHours)}
                >
                  {showOffseasonHours ? 'Nebensaison schließen' : 'Nebensaison anzeigen'}
                </button>

                {showOffseasonHours && (
                  <table className="opening-hours-table offseason-hours">
                    <tbody>
                      <tr>
                        <td>Montag:</td>
                        <td>10:00 - 18:00 Uhr</td>
                      </tr>
                      <tr>
                        <td>Dienstag:</td>
                        <td>10:00 - 18:00 Uhr</td>
                      </tr>
                      <tr>
                        <td>Mittwoch:</td>
                        <td>10:00 - 18:00 Uhr</td>
                      </tr>
                      <tr>
                        <td>Donnerstag:</td>
                        <td>10:00 - 18:00 Uhr</td>
                      </tr>
                      <tr>
                        <td>Freitag:</td>
                        <td>10:00 - 19:00 Uhr</td>
                      </tr>
                      <tr>
                        <td>Samstag:</td>
                        <td>10:00 - 19:00 Uhr</td>
                      </tr>
                      <tr>
                        <td>Sonntag:</td>
                        <td>10:00 - 19:00 Uhr</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
